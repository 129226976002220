.root {
  height: 100%;
  justify-content: center;
  display: flex!important;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0!important;

  & :global(.menu-flags-button){
    border: none!important;
  }
  
  ul {
    top: 0;
  }
}



.select {
  composes: textSmall from global;
  text-decoration: inherit;
}



#rfs-btn {
  border: none!important;
}